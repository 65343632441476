import logo from "../assets/images/logo.svg";
import mapIcon from "../assets/images/map-view.svg";
import listIcon from "../assets/images/list-view.svg";

const ViewText = (props) => {
    return (
        <>
            <span>{props.view === "map" ? "List" : "Map"} View</span>
            <img src={props.view === "map" ? listIcon : mapIcon} alt="" />
        </>
    );
};

const Header = (props) => {
    const handleView = () => {
        if (props.view === "list") {
            props.setView("map");
        } else {
            props.setView("list");
        }
    };

    return (
        <header>
            <img src={logo} alt="BK Plus" />
            {!props.active && (
                <button onClick={handleView}>
                    <ViewText view={props.view} />
                </button>
            )}
            {props.active && (
                <button
                    onClick={() => props.setActive(null)}
                    className="close-button"
                >
                    &times;
                </button>
            )}
        </header>
    );
};

export default Header;
