import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import logo from "../assets/images/logo.svg";
import cooperfields from "../assets/images/cooperfields.svg";

const Home = () => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    return (
        <main className="login">
            <div className="button-wrapper">
                <img src={logo} alt="BK Plus" className="logo" />
                {!isAuthenticated && (
                    <button
                        onClick={() =>
                            loginWithRedirect({
                                redirectUri: `${window.location.origin}/map`,
                            })
                        }
                    >
                        Log in
                    </button>
                )}
                {isAuthenticated && <Link to="/map">View tracker</Link>}
                <p class="disclaimer">This tool is intended for internal use only.</p>
            </div>
            <a
                href="https://cooperfields.co.uk"
                target="_blank"
                rel="noreferrer"
                className="credit"
            >
                <span>Designed &amp; developed by </span>
                <img src={cooperfields} alt="Cooperfields" />
            </a>
        </main>
    );
};

export default Home;
