import { useState, useEffect } from "react";

const Totals = (props) => {
    const [offices, setOffices] = useState(null);
    const [turnover, setTurnover] = useState(null);
    const [employees, setEmployees] = useState(null);

    useEffect(() => {
        if (props.locations) {
            let turnoverSum = 0;
            let employeesSum = 0;

            props.locations.map((location) => {
                if (location.turnover) turnoverSum += location.turnover;
                if (location.staff) employeesSum += location.staff;
            });

            setOffices(props.locations.length);
            setTurnover(turnoverSum);
            setEmployees(employeesSum);
        }
    }, [props.locations]);

    let GBP = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: 0,
    });

    return (
        <div id="totals">
            <p>Totals:</p>
            <div className="values">
                <p>{offices} Offices</p>
                <p>{GBP.format(turnover)} t/o</p>
                <p>{employees} Employees</p>
            </div>
        </div>
    );
};

export default Totals;
