import React from "react";
import { Router, Route, Switch } from "react-router-dom";

import Loading from "./components/Loading";
import Home from "./views/Home";
import Map from "./views/Map";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import './fonts/font.css';
import "./App.scss";

const App = () => {
    const { isLoading, error } = useAuth0();

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Router history={history}>
            <div id="app">
                <Switch>
                    <Route path="/" exact component={Home}></Route>
                    <Route path="/map" component={Map} />
                </Switch>
            </div>
        </Router>
    );
};

export default App;
