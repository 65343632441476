import { useRef, useState, useEffect, useMemo } from "react";
import searchIcon from "../assets/images/search.svg";

const Search = (props) => {
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const debounce = (func, timeout = 500) => {
        let timer;

        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    };

    const useDebounce = (callback) => {
        const ref = useRef();

        useEffect(() => {
            ref.current = callback;
        }, [callback]);

        const debouncedCallback = useMemo(() => {
            const func = () => {
                ref.current?.();
            };

            return debounce(func, 500);
        }, []);

        return debouncedCallback;
    };

    const onChange = (e) => {
        const value = e.target.value;
        setQuery(value);

        handleQuery();
    };

    const onClear = () => {
        setQuery("");
        setSuggestions([]);
    };

    const handleQuery = useDebounce(() => {
        let matches = props.locations.filter((location) => {
            return location.name.toLowerCase().includes(query.toLowerCase());
        });
        setSuggestions(matches);
    });

    const handleClick = (location) => {
        setQuery('');
        setSuggestions([]);
        props.setActive(location);
    };

    return (
        <form>
            <img src={searchIcon} alt="Search" />
            <input
                type="text"
                value={query}
                placeholder="Search by office name"
                onChange={onChange}
            />
            {query !== "" && (
                <input type="reset" value="&times;" onClick={onClear} />
            )}
            <ul className="search-results">
                {suggestions &&
                    suggestions.map((location) => (
                        <li
                            key={location._id}
                            onClick={() => handleClick(location)}
                        >
                            <p>
                                <strong>{location.name}</strong> {"("}{location.status}{")"}
                            </p>
                        </li>
                    ))}
            </ul>
        </form>
    );
};

export default Search;
