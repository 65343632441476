import React, { useState, useEffect } from "react";
import { client } from "../client";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import GoogleMaps from "../components/GoogleMaps";

export const Map = () => {
    const [data, setData] = useState(null);
    const [locations, setLocations] = useState(null);
    const [active, setActive] = useState(null);
    const [expanded, setExpanded] = useState(true);
    const [view, setView] = useState("map");

    const handleSetActive = (location) => {
        setActive(location);
    };

    const handleSetLocations = (newData) => {
        setLocations(newData);
    };

    useEffect(() => {
        // fetch and sort newest to oldest
        client
            .fetch(
                `*[_type == 'location']{
                    _id,
                    _createdAt,
                    dateAdded,
                    name,
                    status,
                    type,
                    address,
                    lat,
                    lng,
                    turnover,
                    staff,
                    rmp,
                    introducer,
                    notes
                } | order(dateAdded desc)`
            )
            .then((data) => setData(data))
            .catch(console.error);
    }, []);

    useEffect(() => {
        setLocations(data);
    }, [data]);

    return (
        <div id="map-container" className={expanded ? "expanded" : ""}>
            <Header view={view} setView={setView} active={active} setActive={setActive} />
            <Sidebar
                data={data}
                locations={locations}
                setLocations={handleSetLocations}
                active={active}
                setActive={handleSetActive}
                expanded={expanded}
                view={view}
            />
            <GoogleMaps
                data={data}
                locations={locations}
                active={active}
                setActive={handleSetActive}
                expanded={expanded}
                setExpanded={setExpanded}
                setView={setView}
            />
        </div>
    );
};

export default withAuthenticationRequired(Map, {
    onRedirecting: () => <Loading />,
});
