import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import { useCallback, useEffect, useState } from "react";
import logo from '../assets/images/logo.svg';
import Search from './Search';
import Totals from "./Totals";
import arrowIcon from "../assets/images/arrow.svg";

const GoogleMaps = (props) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [map, setMap] = useState(null);

    const onLoad = useCallback((map) => setMap(map), []);

    const createBounds = (markers) => {
        const bounds = new window.google.maps.LatLngBounds();
        markers.forEach((location) => {
            if (location.lat && location.lng) {
                bounds.extend({
                    lat: location.lat,
                    lng: location.lng,
                });
            }
        });
        map.fitBounds(bounds);
    };

    useEffect(() => {
        if (map && props.locations) createBounds(props.locations);
    }, [map, props.locations]);

    useEffect(() => {
        if (map) {
            if (!props.active || (!props.active.lat || !props.active.lng)) {
                createBounds(props.locations);
            } else {
                map.setCenter({ lat: props.active.lat, lng: props.active.lng });
                map.setZoom(14);
            }
        }
    }, [props.active]);

    if (!isLoaded) return <p>Loading...</p>;

    return (
        <GoogleMap
            zoom={15}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={{
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
            }}
            onLoad={onLoad}
        >
            <div className="search-wrapper">
                <Search {...props} />
                <img src={logo} alt="BK Plus" />
            </div>
            {props.locations &&
                props.locations.map((location) => {
                    let locationStatus;
                    let borderColor;

                    switch (location.status) {
                        case "Completed":
                            locationStatus = "#981869";
                            borderColor = "#520034";
                            break;
                        case "In Legals":
                            locationStatus = "#186998";
                            borderColor = "#003c5e";
                            break;
                        case "Initial Targets":
                            locationStatus = "#3EA8E4";
                            borderColor = "#1E3A5F";
                            break;
                        default:
                            locationStatus = "#ed9b1f";
                            borderColor = "#965b00";
                            break;
                    }

                    if (!location.lat || !location.lng) return;

                    return (
                        <Marker
                            key={location._id}
                            position={{
                                lat: location.lat,
                                lng: location.lng,
                            }}
                            onClick={() => props.setActive(location)}
                            icon={{
                                path: "M12 2C7.589 2 4 5.589 4 9.995 3.971 16.44 11.696 21.784 12 22c0 0 8.029-5.56 8-12 0-4.411-3.589-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z",
                                fillColor: locationStatus,
                                fillOpacity: 1,
                                rotation: 0,
                                scale: 1.25,
                                strokeWeight: 2,
                                strokeColor: borderColor,
                                anchor: new window.google.maps.Point(12, 24),
                            }}
                        />
                    );
                })}
            {!props.active && <Totals {...props} />}
            <div className="toggle-wrapper">
                <button
                    className={`expand-drawer ${
                        props.expanded ? "expanded" : ""
                    }`}
                    onClick={() => props.setExpanded((prev) => !prev)}
                >
                    <img src={arrowIcon} alt="Toggle" />
                </button>
            </div>
        </GoogleMap>
    );
};

export default GoogleMaps;
