import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Location from "../components/Location";
import Sort from "../components/Sort";
import Status from "../components/Status";

const Content = (props) => {
    const [order, setOrder] = useState("newest");

    const handleSort = (a, b) => {
        if (order === "newest") {
            return new Date(b.dateAdded) - new Date(a.dateAdded);
        } else if (order === "oldest") {
            return new Date(a.dateAdded) - new Date(b.dateAdded);
        } else if (order === "turnover") {
            return b.turnover - a.turnover;
        } else if (order === "employees") {
            return b.staff - a.staff;
        }
    };

    let GBP = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        maximumFractionDigits: 0,
    });

    if (props.active) {
        let address, notes;

        if (props.active.address) address = props.active.address.split(/\n/g);
        if (props.active.notes) notes = props.active.notes.split(/\n/g);

        return (
            <article>
                <button
                    onClick={() => props.setActive(null)}
                    className="close-button"
                >
                    &times;
                </button>
                <h2>{props.active.name}</h2>
                <p>Status: {props.active.status}</p>

                <p>
                    {address &&
                        address.map((line, key) => (
                            <span key={key}>{line}</span>
                        ))}
                </p>

                <p>
                    Turnover: {GBP.format(props.active.turnover)}
                    <br />
                    Employees: {props.active.staff}
                    <br />
                    Type: {props.active.type}
                    <br />
                    RMP: {props.active.rmp}
                    <br />
                    Introducer: {props.active.introducer}
                </p>

                <section className="notes">
                    <h3>Notes:</h3>
                    {notes && notes.map((line, key) => <p key={key}>{line}</p>)}
                </section>
            </article>
        );
    }

    return (
        <>
            <Sort {...props} order={order} setOrder={setOrder} />
            <Status {...props} />
            <ul id="locations">
                {props.locations &&
                    props.locations.sort(handleSort).map((location) => {
                        return (
                            <li key={location._id}>
                                <Location
                                    location={location}
                                    setActive={props.setActive}
                                />
                            </li>
                        );
                    })}
            </ul>
        </>
    );
};

const Sidebar = (props) => {
    const { logout } = useAuth0();

    return (
        <aside className={props.view === "map" && !props.active ? "hidden" : ""}>
            <Content {...props} />
            <button
                onClick={() => logout()}
                className="button-primary"
                style={{ display: "block", margin: "1rem auto" }}
            >
                Logout
            </button>
        </aside>
    );
};

export default Sidebar;
