import { useState, useEffect, useRef } from "react";
import arrowIcon from "../assets/images/arrow.svg";

const Status = (props) => {
    const [completed, setCompleted] = useState(true);
    const [inLegals, setInLegals] = useState(true);
    const [pipeline, setPipeline] = useState(true);
    const [targets, setTargets] = useState(true);
    const [expanded, setExpanded] = useState(false);

    const isMounted = useRef(false);

    useEffect(() => {
        props.setActive(null);
        
        if (isMounted.current) {
            let filters = [
                ...completed ? ['Completed'] : [],
                ...inLegals ? ['In Legals'] : [],
                ...pipeline ? ['Pipeline'] : [],
                ...targets ? ['Initial Targets'] : []
            ];

            let filteredData = props.data.filter(location => {
                return filters.includes(location.status);
            });

            props.setLocations(filteredData);
        } else {
            isMounted.current = true;
        }
    }, [completed, inLegals, pipeline, targets]);

    return (
        <div id="status-filter" className={expanded ? 'expanded' : ''}>
            <div className="status-indicators" onClick={() => setExpanded((prev) => !prev)}>
                <h3>Status</h3>
                <div>
                    {completed && (
                        <div
                            className="indicator"
                            style={{ backgroundColor: "#981869" }}
                        />
                    )}
                    {inLegals && (
                        <div
                            className="indicator"
                            style={{ backgroundColor: "#186998" }}
                        />
                    )}
                    {pipeline && (
                        <div
                            className="indicator"
                            style={{ backgroundColor: "#ed9b1f" }}
                        />
                    )}
                    {targets && (
                        <div
                            className="indicator"
                            style={{ backgroundColor: "#3EA8E4" }}
                        />
                    )}
                    <img src={arrowIcon} alt="Toggle filter" className="arrow" />
                </div>
            </div>
            <form>
                <label className="color-block">
                    <input
                        type="checkbox"
                        value="Completed"
                        onChange={() => setCompleted((prev) => !prev)}
                        checked={completed}
                    />
                    <div
                        className="checkbox"
                        style={{
                            backgroundColor: "#981869",
                            borderColor: "#981869",
                        }}
                    />{" "}
                    Completed
                </label>
                <label className="color-block">
                    <input
                        type="checkbox"
                        value="In Legals"
                        onChange={() => setInLegals((prev) => !prev)}
                        checked={inLegals}
                    />
                    <div
                        className="checkbox"
                        style={{
                            backgroundColor: "#186998",
                            borderColor: "#186998",
                        }}
                    />{" "}
                    In Legals
                </label>
                <label className="color-block">
                    <input
                        type="checkbox"
                        value="Pipeline"
                        onChange={() => setPipeline((prev) => !prev)}
                        checked={pipeline}
                    />
                    <div
                        className="checkbox"
                        style={{
                            backgroundColor: "#ed9b1f",
                            borderColor: "#ed9b1f",
                        }}
                    />{" "}
                    Active Pipeline
                </label>
                <label className="color-block">
                    <input
                        type="checkbox"
                        value="Initial Targets"
                        onChange={() => setTargets((prev) => !prev)}
                        checked={targets}
                    />
                    <div
                        className="checkbox"
                        style={{
                            backgroundColor: "#3EA8E4",
                            borderColor: "#3EA8E4",
                        }}
                    />{" "}
                    Initial Targets
                </label>
            </form>
        </div>
    );
};

export default Status;
