import detailsIcon from '../assets/images/details.svg';

const Location = (props) => {
    let address;

    if (props.location.address) {
        address = props.location.address.split(/\n/g);
    }

    return (
        <>
            <h2>{props.location.name}</h2>
            <p>
                {address && address.map((line, key) => (
                    <span key={key}>{line}</span>
                ))}
                {!address && (
                    <>No address found</>
                )}
            </p>
            <button onClick={() => props.setActive(props.location)}>
                <img src={detailsIcon} alt="" />
                <span>Details</span>
            </button>
        </>
    );
};

export default Location;
