const Sort = (props) => {
    return (
        <div id="sort-options">
            <h3>Sort by</h3>
            <div>
                <button
                    onClick={() => props.setOrder("newest")}
                    disabled={props.order === "newest"}
                >
                    Newest
                </button>
                <button
                    onClick={() => props.setOrder("oldest")}
                    disabled={props.order === "oldest"}
                >
                    Oldest
                </button>
                <button
                    onClick={() => props.setOrder("turnover")}
                    disabled={props.order === "turnover"}
                >
                    Turnover
                </button>
                <button
                    onClick={() => props.setOrder("employees")}
                    disabled={props.order === "employees"}
                >
                    Employees
                </button>
            </div>
        </div>
    );
}

export default Sort;